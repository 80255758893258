import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Customized,
} from "recharts";
import { getInspections } from "../../redux";
import { formatInspection } from "../../utils";
import { useSelector } from "react-redux";
import { projectByIdSelector } from "../../redux";
import styled from "styled-components";

const ChartContainer = styled.div`
  .scatter-chart {
    svg {
      border: none !important;
      outline: none !important;
    }
  }
`;

interface Coordinate {
  x: number;
  y: number;
}

interface ChartDates {
  startDate: Date | null;
  maturityDate: Date | null;
  expectedCompletionDate: Date | null;
  predictedCompletionDate: Date | null;
  minPredictedCompletionDate: Date | null;
  maxPredictedCompletionDate: Date | null;
}

interface InspectionsState {
  inspections: {
    inspections: Array<{
      inspDate: string;
      inspCompleted: string;
      // Add other inspection properties as needed
    }>;
  };
}

// Added explicit interface for Axis Props because
//  angle is missing from
//  recharts/types/cartesian/XAxis.d.ts
interface AxisTick {
  angle: number;
  textAnchor: string;
  dy: number;
  fontSize: string;
  fill: string;
}

const formatDateString = (timestamp: number): string => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};

const generateMonthlyTicks = (domainDates: number[]): number[] => {
  if (domainDates.length < 2) {
    return [];
  }
  const start = new Date(domainDates[0]);
  const end = new Date(domainDates[1]);
  end.setMonth(end.getMonth() + 1); // Increment the end date by one month
  end.setDate(1);
  const ticks: number[] = [];

  const monthCount =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());
  let monthInterval = 1;
  if (monthCount > 70) {
    monthInterval = 3;
  } else if (monthCount > 35) {
    monthInterval = 2;
  }
  let currentDate = new Date(start);
  currentDate.setDate(1); // Ensure we start on the 1st of the month

  while (currentDate <= end) {
    ticks.push(currentDate.getTime());
    currentDate.setMonth(currentDate.getMonth() + monthInterval);
  }
  return ticks;
};

const CustomShape = (props) => {
  const { cx, cy, fill } = props;
  return (
    <g>
      <line
        x1={cx - 8}
        y1={cy - 8}
        x2={cx + 8}
        y2={cy + 8}
        stroke={fill}
        strokeWidth={3}
      />
      <line
        x1={cx - 8}
        y1={cy + 8}
        x2={cx + 8}
        y2={cy - 8}
        stroke={fill}
        strokeWidth={3}
      />
    </g>
  );
};

const MyPolygon = (props) => {
  if (props.points.length === 0) {
    return null;
  }
  const scaledPoints = props.points
    .split(" ")
    .map((point) => {
      const [x, y] = point.split(",");
      const scaledX = props.xAxisMap[0].scale(Number(x));
      const scaledY = props.yAxisMap[0].scale(Number(y));
      return `${scaledX},${scaledY}`;
    })
    .join(" ");
  if (scaledPoints.length === 0) {
    return null;
  }
  return (
    <g>
      <polygon
        points={scaledPoints}
        fill={props.fill || "rgba(0,255,0,0.1)"}
        stroke={props.stroke || "green"}
      />
    </g>
  );
};

export const ProjectProgressChart: React.FC<{
  pId: string | undefined;
  cId: string | undefined;
  onReady?: () => void;
}> = ({ pId, cId, onReady }) => {
  const dispatch = useDispatch();
  const [inspections, setInspections] = useState<any[]>([]);
  const [points, setPoints] = useState<Coordinate[]>([]);
  const [domain, setDomain] = useState<any[]>([]);
  const [chartDates, setChartDates] = useState<ChartDates>({
    startDate: null,
    maturityDate: null,
    expectedCompletionDate: null,
    predictedCompletionDate: null,
    minPredictedCompletionDate: null,
    maxPredictedCompletionDate: null,
  });

  const [hasAttemptedInspectionsFetch, setHasAttemptedInspectionsFetch] =
    useState(false);
  const { currentProject } = useSelector(projectByIdSelector);
  const inspectionsState = useSelector(
    (state: InspectionsState) => state.inspections?.inspections
  );

  // 1. Clear Inspections when project ID changes
  useEffect(() => {
    // console.log("1. useEffect/clearInspections");
    if (!pId) {
      // console.log("    Missing pId");
      return;
    }
    setHasAttemptedInspectionsFetch(false);
    // Create the action object directly to guarantee its structure
    const clearInspectionsAction = {
      type: "SET_INSPECTIONS",
      payload: { inspections: [] },
    };
    dispatch(clearInspectionsAction);
  }, [pId]);

  // 2. Fetch Inspections when project ID changes
  useEffect(() => {
    // console.log("2. useEffect/loadInspectionData");
    if (!pId || !cId || hasAttemptedInspectionsFetch) {
      // if (!pId) {
      //   console.log("    Missing pId");
      // }
      // if (!cId) {
      //   console.log("    Missing cId");
      // }
      // if (hasAttemptedInspectionsFetch) {
      //   console.log("    already attempted fetch");
      // }
      return;
    }

    setHasAttemptedInspectionsFetch(true);
    dispatch(getInspections({ pId, cId }, () => {}));
  }, [pId, cId, hasAttemptedInspectionsFetch]);

  // 3. Update Inspections when Inspections state changes
  useEffect(() => {
    // console.log("3. useEffect/updateInspections");
    if (!inspectionsState) {
      // console.log("    Missing inspectionsState");
      return;
    }
    const formattedData = formatInspection(inspectionsState)
      .map((item) => ({
        time: item.inspDate?.display
          ? new Date(
              new Date(item.inspDate.display).toLocaleString("en-US", {
                timeZone: "UTC",
              })
            ).getTime()
          : null,
        percent_complete: parseFloat(item.inspCompleted.replace("%", "")),
      }))
      .sort((a, b) => a.time - b.time);

    // console.log("Formatted Data:", formattedData);
    setInspections(formattedData);
  }, [inspectionsState]);

  // 4. Update ChartDates and Domain when Project or Inspections change
  useEffect(() => {
    // console.log("4. useEffect/updateDomain&ChartDates");
    if (!currentProject || !inspectionsState?.length) {
      // if (!currentProject) {
      //   console.log("    Missing currentProject");
      // }
      // if (!inspectionsState) {
      //   console.log("    Missing inspectionsState");
      // }
      return;
    }

    const createLocalDate = (utcDate) =>
      utcDate
        ? new Date(
            new Date(utcDate).toLocaleString("en-US", { timeZone: "UTC" })
          )
        : null;

    interface Dates {
      startDate: Date | null;
      maturityDate: Date | null;
      expectedCompletionDate: Date | null;
      predictedCompletionDate: Date | null;
      minPredictedCompletionDate: Date | null;
      maxPredictedCompletionDate: Date | null;
    }
    const dates: Dates = {
      startDate: createLocalDate(currentProject?.fundedDate),
      maturityDate: createLocalDate(currentProject?.matDate),
      expectedCompletionDate: createLocalDate(currentProject?.expCompdate),
      predictedCompletionDate: createLocalDate(currentProject?.predDate),
      minPredictedCompletionDate: null,
      maxPredictedCompletionDate: null,
    };

    // Add predicted date ranges if we have a predicted completion date
    if (dates.predictedCompletionDate) {
      const msPerDay = 24 * 60 * 60 * 1000;
      dates.minPredictedCompletionDate = new Date(
        dates.predictedCompletionDate.getTime() - 15 * msPerDay
      );
      dates.maxPredictedCompletionDate = new Date(
        dates.predictedCompletionDate.getTime() + 15 * msPerDay
      );
    }

    setChartDates(dates);

    // console.log("  dates", dates);
    // console.log("  first inspection:", inspectionsState[0].inspDate);
    // console.log("  last inspection:", inspectionsState[inspectionsState.length - 1].inspDate);

    // Calculate domain with filtered timestamps
    const minTimestamps = [
      dates.startDate,
      dates.minPredictedCompletionDate,
      new Date(inspectionsState[0].inspDate),
    ]
      .filter(Boolean)
      .map((date) => date!.getTime())
      .filter((t) => !isNaN(t));

    const maxTimestamps = [
      dates.maturityDate,
      dates.expectedCompletionDate,
      dates.maxPredictedCompletionDate,
      new Date(inspectionsState[inspectionsState.length - 1].inspDate),
    ]
      .filter(Boolean)
      .map((date) => date!.getTime())
      .filter((t) => !isNaN(t));

    const minDate = new Date(Math.min(...minTimestamps));
    minDate.setMonth(minDate.getMonth() - 1);
    // console.log("  minDate:", minDate);
    const maxDate = new Date(Math.max(...maxTimestamps));
    maxDate.setMonth(maxDate.getMonth() + 1);
    // console.log("  maxDate:", maxDate);

    setDomain([minDate.getTime(), maxDate.getTime()]);
  }, [currentProject, inspectionsState]);

  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  // 5. Update Points for prediction cone
  useEffect(() => {
    // console.log("5. useEffect/calculatePoints");
    if (
      !inspections.length ||
      !chartDates.minPredictedCompletionDate ||
      !chartDates.maxPredictedCompletionDate
    ) {
      // if (!inspections.length) {
      //   console.log("    Missing inspections");
      // }
      // if (!chartDates.minPredictedCompletionDate || !chartDates.maxPredictedCompletionDate) {
      //   console.log("    Missing chartDates");
      // }
      return;
    }
    const latestProgress = inspections[inspections.length - 1];
    setPoints([
      { x: latestProgress.time, y: latestProgress.percent_complete },
      { x: chartDates.minPredictedCompletionDate.getTime(), y: 100 },
      { x: chartDates.maxPredictedCompletionDate.getTime(), y: 100 },
    ]);
  }, [
    inspections,
    chartDates.minPredictedCompletionDate,
    chartDates.maxPredictedCompletionDate,
  ]);

  // 6. Call onReady only when all data is ready
  useEffect(() => {
    // console.log("6. useEffect - call onReady when all chart data is available");
    if (
      currentProject?.pId === pId &&
      currentProject &&
      inspections.length > 0 &&
      domain.length === 2 &&
      points.length > 2
    ) {
      onReady?.();
    }
  }, [currentProject, inspections, domain, points, onReady, pId]);

  return (
    <ChartContainer>
      <ScatterChart
        width={1006}
        height={716}
        margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
        style={{
          border: "none !important",
          outline: "none !important",
          backgroundColor: "white",
        }}
        className="scatter-chart"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          type="number"
          domain={domain}
          tickFormatter={formatDateString}
          tick={{
            angle: -45,
            textAnchor: "end",
            dy: 0,
            fontSize: "14px",
            fill: "black",
          } as AxisTick}
          ticks={generateMonthlyTicks(domain)}
          interval={0}
        />
        <YAxis
          dataKey={"percent_complete"}
          type="number"
          label={{
            value: "Percent Complete",
            angle: -90,
            position: "insideLeft",
            style: { fontSize: "14px", fill: "black" },
          }}
          domain={[0, 100]}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tick={{ fontSize: "14px", fill: "black" }}
        />
        <Tooltip
          labelFormatter={(label) => new Date(label).toLocaleDateString()}
        />
        <Scatter
          name="Progress"
          data={inspections}
          fill="blue"
          shape={<CustomShape />}
        />
        {chartDates.startDate && (
          <ReferenceLine
            x={chartDates.startDate.getTime()}
            stroke="green"
            orientation={-90}
            label={{
              value: chartDates.startDate
                ? `Start Date (${formatter.format(chartDates.startDate)})`
                : "",
              offset: 20,
              angle: -90,
              position: "insideBottomLeft",
              style: { fontSize: "14px", fill: "black" },
            }}
          />
        )}
        {(chartDates.maturityDate || chartDates.expectedCompletionDate) && (
          <ReferenceLine
            x={
              chartDates.maturityDate
                ? chartDates.maturityDate.getTime()
                : chartDates.expectedCompletionDate
                ? chartDates.expectedCompletionDate.getTime()
                : 0
            }
            stroke="red"
            orientation={-90}
            label={{
              value: `${
                chartDates.maturityDate ? "Maturity" : "Expected Completion"
              } Date (${
                chartDates.maturityDate
                  ? formatter.format(chartDates.maturityDate!!)
                  : formatter.format(chartDates.expectedCompletionDate!!)
              })`,
              offset: 20,
              angle: -90,
              position: "insideBottomLeft",
              style: { fontSize: "14px", fill: "black" },
            }}
          />
        )}
        {points.length > 2 && (
          <Customized
            name="ConePoly"
            points={
              points
                ? points.map((point) => `${point.x},${point.y}`).join(" ")
                : []
            }
            stroke="rgba(0,255,0,0.75)"
            fill="rgba(0,255,0,0.25)"
            component={MyPolygon}
          />
        )}
      </ScatterChart>
    </ChartContainer>
  );
};

export default ProjectProgressChart;
