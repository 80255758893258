import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  HeaderSection,
  MainContainer,
  Type,
  DeleteButton,
  Container,
  BackButton,
} from "./ProjectPreview.styles";

import BackIcon from "../../assets/images/Common/back-icon.png";
import DeleteIcon from "../../assets/images/Common/deleteIcon.png";
import { useReadOnlyUser } from "../../hooks/useReadOnlyUser";
import { projectByIdSelector, deleteProject, budgetItemsSelector } from "../../redux";
import { getProjectById } from "../../redux/actions/get/getProjectById"; // Import the action
import { getBudgetItems } from "../../redux";
import { exportChartsToPdf } from "../ProjectProgressChart/ChartExport";

import Tabs from "../Tabs/Tabs";
import { PageContainer } from "../Projects/Projects.styles";
import DeleteProject from "../DeleteProject/DeleteProject";
import ProjectInformation from "../ProjectInformation/ProjectInformation";
import BudgetList from "../BudgetList/BudgetList";
import UnitInformation from "../UnitInformation/UnitInformation";
import InspectionData from "../InspectionData/Inspection";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import EOCReport from "../EOCReport/EOCReport";

import {
  getTableauToken,
  exportTableauViewToPdf,
} from "../../redux/actions/get/getTableauToken";
import ClipLoader from "react-spinners/ClipLoader";

const ProjectPreview: React.FC = () => {
  const budget = useSelector(budgetItemsSelector);
  const [isDelete, setDelete] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [isExportingBAT, setIsExportingBAT] = React.useState(false);
  const [isExportingEOC, setIsExportingEOC] = React.useState(false);
  const [isChartReady, setIsChartReady] = React.useState(false);

  // Add this handler
  const handleChartReady = () => {
    // console.log("currentProject", currentProject);
    setIsChartReady(true);
  };

  const handleExportBAT = async () => {
    setIsExportingBAT(true);
    await dispatch(exportTableauViewToPdf(projectId || "", "BAT"));
    setIsExportingBAT(false);
  };

  const handleExportEOC = async () => {
    setIsExportingEOC(true);

    // Store current project ID to verify consistency
    const currentPid = projectId;

    const waitForChart = new Promise((resolve, reject) => {
      const observer = new MutationObserver((mutations, obs) => {
        const reportContainer = document.querySelector('[custom-id="report-container"]');
        if (reportContainer && currentPid === projectId) {
          // Give a small delay for final render
          setTimeout(() => {
            obs.disconnect();
            resolve(true);
          }, 500);
        } else if (currentPid !== projectId) {
          obs.disconnect();
          reject(new Error('Project changed during export'));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });

    try {
      await waitForChart;
      await exportChartsToPdf();
    } catch (error) {
      toast.error('Please wait for data to load before exporting');
    } finally {
      setIsExportingEOC(false);
    }
  };

  const dispatch = useDispatch();
  const { currentProject } = useSelector(projectByIdSelector);
  const inspectionsState = useSelector(state => state.inspections?.inspections);
  const { pName } = currentProject || {};
  const [isLoading, setIsLoading] = React.useState(false);
  const isReadonlyUser = useReadOnlyUser();

  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (budget.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [budget]);

  useEffect(() => {
    if (companyId && projectId) {
      dispatch(
        getBudgetItems({ pId: projectId, cId: companyId }, (result) => {
        })
      );
    }
  }, [dispatch, companyId, projectId]);
  
  useEffect(() => {
    if (companyId && projectId) {
      dispatch(getProjectById({ companyId, projectId }));
    }
  }, [dispatch, companyId, projectId]);

  const onDeleteHandler = () => {
    setIsLoading(true);
    dispatch(
      deleteProject({ companyId, projectId }, (res) => {
        setDelete(false);
        setIsLoading(false);
        if (res?.success) {
          notifySuccess(res?.message, () => {
            navigate(-1);
          });
        } else {
          notifyError(res?.message || "Not able to delete project");
        }
      })
    );
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message, onclose) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: onclose,
    });
  };

  return (
    <PageContainer>
      <MainContainer>
        {isDelete && (
          <DeleteProject
            setShow={() => setDelete(false)}
            projectId={projectId}
            companyId={companyId}
            onDelete={onDeleteHandler}
            isLoading={isLoading}
          />
        )}
        <HeaderSection>
          <BackButton
            onClick={() => navigate(`/home/${companyId}`)}
            type="button"
          >
            <img src={BackIcon} alt="level" />
          </BackButton>
          {projectId && <Type>{pName}</Type>}
          <DeleteButton
            onClick={() => {
              setDelete(true);
            }}
            disabled={isReadonlyUser}
          >
            <img src={DeleteIcon} alt="menuButton" title="Delete Project" />
          </DeleteButton>
        </HeaderSection>
        <Container>
          <Tabs
            tabData={[
              {
                label: "Project Information",
                value: "1",
                content: (
                  <ProjectInformation
                    projectId={projectId}
                    companyId={companyId ?? ""}
                  />
                ),
              },
              {
                label: "Budgets",
                value: "2",
                content: (
                  <BudgetList
                    pId={projectId}
                    cId={companyId ?? ""}
                    pName={pName}
                  />
                ),
              },
              {
                label: "Unit Information",
                value: "3",
                content: (
                  <UnitInformation
                    pId={projectId}
                    cId={companyId ?? ""}
                    pName={pName}
                  />
                ),
              },
              {
                label: "Inspection Data",
                value: "4",
                content: (
                  <InspectionData
                    pId={projectId ?? ""}
                    cId={companyId ?? ""}
                    pName={pName}
                  />
                ),
              },
            ]}
            
         batButton={
              <div
                title={
                  isButtonDisabled
                    ? "Budget report is not available due to missing budget records, budget amounts"
                    : "Generate Budget Analysis report as a PDF file"
                }
              >
                {isExportingBAT ? (
                  <ClipLoader color="#36D7B7" loading={isExportingBAT} size={35} />
                ) : (
                  <Button
                    variant="primary"
                    size="small"
                    onClick={handleExportBAT}
                    disabled={isExportingBAT || isButtonDisabled}
                  >
                    Budget PDF
                  </Button>
                )}
              </div>
            }
            
            eocButton = {isExportingEOC ? (
                <ClipLoader color="#36D7B7" loading={isExportingEOC} size={35} />
              ) : (
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleExportEOC}
                  disabled={isExportingEOC|| !isChartReady || !inspectionsState?.length}
                  title="Generate Timeline Report as a PDF file"
                >
                  Timeline PDF
                </Button>
              )}
        />
        </Container>
      </MainContainer>
      <div style={{ position: 'absolute', left: '-9999px' }}>
        <EOCReport
          pId={projectId}
          cId={companyId ?? ""}
          onChartReady={handleChartReady}
        />
      </div>
    </PageContainer>
  );
};

export default ProjectPreview;