import jsPDF from "jspdf";
import { toPng } from "html-to-image";

export const exportChartsToPdf = async () => {
  const reportElement = document.querySelector('[custom-id="report-container"]');
  if (!reportElement) {
    // console.log("No <ReportContainer> element found");
    return;
  }

  // Get all pages in the report
  const pages = reportElement.querySelectorAll('[custom-id="page"]');
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: "letter",
  });

  // Capture each page and add to PDF
  for (let i = 0; i < pages.length; i++) {
    const dataUrl = await toPng(pages[i] as HTMLElement, {
      style: {
        border: 'none',
        outline: 'none'
      }
    });

    // Add new page if not first page
    if (i > 0) {
      pdf.addPage();
    }

    pdf.addImage(dataUrl, 'PNG', 0.25, 0.25, 10.5, 8);
  }

  pdf.save("EOC_report.pdf");
}
